import styled from "styled-components";

import Card from "../../components/Card";
import PageLayout from "../../components/PageLayout";

export const MissionPageLayout = styled(PageLayout)`
  grid-row-gap: 64px;

  @media (max-width: 1125px) {
    padding: 35px 0 20px;
    grid-row-gap: 20px;
  }
`;

export const LogoSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1125px) {
    flex-direction: column;
  }
`;

export const Mission = styled.div`
  max-width: 398px;

  @media (max-width: 1125px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  animation-name: blurin;
  animation-duration: 2s;

  @keyframes blurin {
    0% {
      filter: blur(10px);
    }
    100% {
      filter: blur(0px);
    }
  }
`;

export const Title = styled.div`
  font-size: 88px;
  line-height: 93px;
  color: #ffffff;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 50px;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  line-height: 160%;
  color: #ffffff;
`;

export const Moon = styled.div`
  min-width: 219px;
  min-height: 219px;
  margin-bottom: 40px;
  animation-name: blurin;
  animation-duration: 2s;

  @keyframes blurin {
    0% {
      filter: blur(10px);
    }
    100% {
      filter: blur(0px);
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 12px;

  @media (max-width: 1125px) {
    justify-content: center;
    align-items: center;
  }
`;

const MissionCard = styled(Card)`
  max-width: 398px;

  @media (max-width: 1125px) {
    padding: 20px;
    max-width: 345px;
    width: 100%;
  }
`;

export const Technology = styled(MissionCard)``;

export const CardTitle = styled.h3`
  margin: 0;
  padding: 0;

  font-size: 30px;
  line-height: 32px;
  color: #ffffff;

  @media (max-width: 1125px) {
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: #ffffff;
  }
`;

export const CardText = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 140%;

  color: #9198af;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  flex-direction: column;
  grid-row-gap: 8px;
  list-style: square inside;
`;

export const ListItem = styled.li`
  &::marker {
    margin-left: 14px;
  }
`;

export const LaunchApp = styled.a`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  color: #be05ff;

  &:hover {
    color: #be05ff;
    background: linear-gradient(to right, #054bff 0%, #be05ff 60%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  svg {
    margin-left: 7px;

    path,
    rect {
      fill: #be05ff;
    }
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;
