import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";

import LinkIcon from "assets/images/copy.svg";

import * as S from "./styles";

const Mission = () => {
  const { t } = useTranslation();

  return (
    <S.MissionPageLayout>
      <S.MainSection>
        <S.Mission>
          <S.Title>{t("Mission")}</S.Title>
          <S.Text>
            {t(
              "Give the maximum number of tokens to a DEX trader by aggregating DEX liquidity and performing arbitrage of connected assets."
            )}
          </S.Text>
          <S.LaunchApp
            href="https://app.wowmax.exchange/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Launch App <LinkIcon />
          </S.LaunchApp>
        </S.Mission>

        <S.Moon>
          <StaticImage
            src="../../assets/images/mission-moon.png"
            alt="Moon mission"
            placeholder="blurred"
            layout="fixed"
            width={219}
          />
        </S.Moon>

        <S.Cards>
          <S.Technology>
            <S.CardTitle>{t("Technology")}</S.CardTitle>

            <S.CardText>
              {t(
                "WOWMAX is the next generation DEX aggregation protocol that uses slippage as an additional source of optimization."
              )}
            </S.CardText>
            <S.CardText>
              {t(
                "We create trading value through both multihop aggregation and same block arbitrage of indirect pairs."
              )}
            </S.CardText>
            <S.CardText>
              {t(
                "WOWMAX exchange protocol brings improvements over key features of the two established DeFi protocols:"
              )}
            </S.CardText>
            <S.List>
              <S.ListItem>{t("Uniswap V2 (Multihop feature)")}</S.ListItem>
              <S.ListItem>{t("1Inch.Exchange (Aggregation feature)")}</S.ListItem>
            </S.List>
          </S.Technology>
        </S.Cards>
      </S.MainSection>
    </S.MissionPageLayout>
  );
};

export default Mission;
